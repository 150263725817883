import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './translations';
import { Users } from './pages/users';
import { Layout } from './views/Layout';
import { SignIn } from './pages/sign-in';
import { SignUp } from './pages/sign-up';
import { useAppInit } from './hooks/useAppInit';
import { SalesLeads } from './pages/sales-leads';
import { ComingSoon } from './pages/coming-soon';
import { Roles } from './pages/roles';
import { Courses } from './pages/courses';
import { Forms } from './pages/forms';
import { Groups } from './pages/groups';
import { ApiTokens } from './pages/api-tokens';
import { Clients } from './pages/clients';
import { SignOut } from './pages/sign-out';
import { AdditionalFields } from './pages/additional-fields';
import { SettingsLayout } from './views/SeettingsLayout';

function App() {
  useAppInit();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/sales-leads" element={<SalesLeads />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/schedule" element={<ComingSoon />} />
        <Route path="/events" element={<ComingSoon />} />
        <Route path="/clients" element={<Clients />} />

        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="" element={<ComingSoon />} />
          <Route path="api-tokens" element={<ApiTokens />} />
          <Route path="roles" element={<Roles />} />
          <Route path="additional-fields" element={<AdditionalFields />} />
        </Route>

        <Route path="/users" element={<Users />} />
        <Route path="/forms" element={<Forms />} />

        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Route>
    </Routes>
  );
}

export default App;
