export const MUIRichTextEditor = {
  styleOverrides: {
    root: {},
    editor: {
      borderTop: 'solid 1px rgba(0, 0, 0, 0.23)',
      minHeight: 54,
      marginTop: 8,
      marginBottom: 8,
    },
  },
};
