import { useCallback, useRef, useState } from 'react';
import { authorizedRequest } from '../../utils/requests';

export const useFileUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef();

  const upload = useCallback(async (formData) => {
    setIsLoading(true);

    try {
      controllerRef.current = new AbortController();

      const data = await authorizedRequest('/files', {
        signal: controllerRef.current.signal,
        params: formData,
        isFile: true,
        method: 'POST',
      });

      setIsLoading(false);
      setIsError(false);

      return data;
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  }, []);

  return { isLoading, isError, upload };
};
