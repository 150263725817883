import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { CheckCircle, Delete, Edit, Unpublished } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { OtherField } from '../../views/OtherField';
import { TextEditor } from '../../views/TextEditor';
import { isJson } from '../../utils/isJson';
import { useColumns } from '../../hooks/useColumns';

const EditModal = memo(({ open, onClose, onSubmit, row, additionalFields }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    values: row
      ? {
          ...row,
          description: isJson(row.description)
            ? JSON.parse(row.description)
            : row.description,
        }
      : {},
  });

  const handleClickSubmit = useCallback(() => {
    handleSubmit(({ other, description, ...rest }) =>
      onSubmit?.({
        ...rest,
        other: JSON.stringify(other),
        description: JSON.stringify(description),
      }),
    )();
    reset();
  }, [onSubmit]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add course')}</DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('name')} />
            )}
          />
          <Controller
            name="shortDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label={t('shortDescription')}
                multiline
                rows={3}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor value={value} onChange={onChange} />
            )}
          />

          <Controller
            name="ageFrom"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('ageFrom')} />
            )}
          />
          <Controller
            name="ageTo"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('ageTo')} />
            )}
          />
          <Controller
            name="enabled"
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    defaultChecked={value}
                    onChange={({ target: { checked } }) =>
                      onChange({ target: { value: checked } })
                    }
                  />
                }
                label={t('enabled')}
              />
            )}
          />
          <Divider />
          {map(additionalFields, (field) => (
            <OtherField control={control} {...field} />
          ))}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleClickSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const Courses = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const url = useRef('/courses');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const courses = useMemo(
    () =>
      map(rows, ({ other, ...row }) => ({
        ...row,
        other: typeof other === 'string' ? JSON.parse(other) : other,
      })),
    [rows],
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);

  const additionalFieldsParams = useRef({
    table: 'courses',
    page: 0,
    size: 200,
  });
  const { rows: additionalFields } = useLoadTableData(
    '/additional-fields',
    additionalFieldsParams.current,
  );

  const columnsKeys = useMemo(
    () => [
      'name',
      'age',
      'enabled',
      ...map(additionalFields, (field) => `other.${field.name}`),
    ],
    [additionalFields],
  );

  const ageColumnRenderer = useCallback((row) => {
    if (!row.ageFrom) {
      return row.ageTo;
    }

    if (!row.ageTo) {
      return row.ageFrom;
    }

    return `${row.ageFrom} - ${row.ageTo}`;
  }, []);

  const enabledColumnRenderer = useCallback((row) => {
    if (row.enabled) {
      return <CheckCircle />;
    }
    return <Unpublished />;
  }, []);

  const getAccessorFn = (columnKey) => {
    switch (columnKey) {
      case 'age':
        return ageColumnRenderer;
      case 'enabled':
        return enabledColumnRenderer;
      default:
        return undefined;
    }
  };

  const getHeader = useCallback(
    (columnKey) => {
      if (columnKey.startsWith('other.')) {
        const [, additionalFieldName] = columnKey.split(/\./);

        return find(additionalFields, { name: additionalFieldName }).title;
      }

      return t(columnKey);
    },
    [additionalFields],
  );

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: getHeader(columnKey),
        accessorFn: getAccessorFn(columnKey),
      })),
    [getHeader],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add course')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  const { setColumnVisibility, columnVisibility, setColumnOrder, columnOrder } =
    useColumns('courses', columnsKeys);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={courses}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{
          isLoading,
          showAlertBanner: isError,
          pagination,
          columnOrder,
          columnVisibility,
        }}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
        enableColumnOrdering
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        additionalFields={additionalFields}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        additionalFields={additionalFields}
      />
    </>
  );
};
