import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { Close, Delete, Done, Edit } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const KanbanColumnHeader = ({ column, onEdit, onDelete }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    values: {
      id: column.id,
      statusName: column.title,
      sortOrder: column.sortOrder,
    },
  });

  const handleChangeEditState = useCallback(() => {
    setIsEdit((oldValue) => !oldValue);
  }, []);

  const handleSave = useCallback(() => {
    handleSubmit(onEdit)();
    reset();
    handleChangeEditState();
  }, [handleSubmit, reset, onEdit]);

  const handleDelete = useCallback(() => {
    if (column.cards.length > 0) {
      setIsWarningOpen(true);
      return;
    }
    onDelete(column.id);
  }, [column, onDelete]);

  const handleCloseWarning = useCallback(() => {
    setIsWarningOpen(false);
  }, []);

  if (isEdit) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 0.5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Controller
          name="statusName"
          control={control}
          render={({ field }) => (
            <InputBase
              {...field}
              size="small"
              label={t('statusName')}
              placeholder={t('title')}
              sx={{ paddingLeft: 1 }}
              fullWidth
            />
          )}
        />
        <IconButton onClick={handleSave}>
          <Done />
        </IconButton>
        <IconButton onClick={handleChangeEditState}>
          <Close />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 0.5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{column.title}</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
          <IconButton size="small" onClick={handleChangeEditState}>
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={isWarningOpen}>
        <DialogContent>
          {t(
            'Status could not be delete because at least one Sales Leads still exists',
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning}>{t('ok')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
