import {
  ButtonBase,
  Card,
  CardContent,
  IconButton,
  InputBase,
  Paper,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Close, Done } from '@mui/icons-material';

const DefaultState = styled(ButtonBase)({
  minWidth: 300,
  border: 'dashed 1px #ccc',
  borderRadius: 4,
  margin: 5,
  padding: 15,
  height: 128.622,
});

export const KanbanColumnAdder = ({ onSave, sortOrder }) => {
  const [isStateAdd, setIsStateAdd] = useState(false);
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    values: {
      statusName: '',
      sortOrder,
    },
  });

  const handleChangeAddState = useCallback(() => {
    setIsStateAdd((oldValue) => !oldValue);
  }, []);

  const handleSave = useCallback(() => {
    handleSubmit(onSave)();
    reset();
    handleChangeAddState();
  }, [handleSubmit, reset, onSave]);

  if (!isStateAdd) {
    return (
      <DefaultState onClick={handleChangeAddState}>
        {t('Add State')}
      </DefaultState>
    );
  }

  return (
    <Card sx={{ minWidth: 300, marginTop: 0.75, height: 124.622 }}>
      <CardContent>
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 250,
          }}
        >
          <Controller
            name="statusName"
            control={control}
            render={({ field }) => (
              <InputBase
                {...field}
                size="small"
                label={t('statusName')}
                placeholder={t('title')}
                sx={{ paddingLeft: 1 }}
                fullWidth
              />
            )}
          />
          <IconButton onClick={handleSave}>
            <Done />
          </IconButton>
          <IconButton onClick={handleChangeAddState}>
            <Close />
          </IconButton>
        </Paper>
      </CardContent>
    </Card>
  );
};
