import { deleteCookie, getCookie, setCookie } from './cookies';
import { API_URL } from '../constants';

export const request = async (url, options) => {
  const {
    params,
    signal,
    authorization,
    method,
    headers: customHeaders,
    isFile,
  } = options;

  const fullUrl = `${API_URL}${url}`;

  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders,
    ...(authorization ? { Authorization: authorization } : {}),
  };

  if (method === 'GET') {
    return fetch(`${fullUrl}?${new URLSearchParams(params)}`, {
      headers,
      signal,
    });
  }

  return fetch(fullUrl, {
    headers,
    signal,
    method,
    body: isFile ? params : JSON.stringify(params),
  });
};

const loadAccessToken = async (refreshToken, signal) => {
  const newAccessTokenResponse = await request('/auth/token/refresh', {
    method: 'GET',
    signal,
    authorization: refreshToken,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!newAccessTokenResponse.ok) {
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    throw new Error('Invalid tokens');
  }

  const data = await newAccessTokenResponse.json();
  setCookie('accessToken', data.accessToken, data.accessTokenExpiration);
};

const addBearerPrefix = (token) => `Bearer ${token}`;

export const authorizedRequest = async (url, options) => {
  const { params, signal, method, headers, isFile } = options;
  let accessToken = getCookie('accessToken');
  const refreshToken = getCookie('refreshToken');

  if (!accessToken && !refreshToken) {
    throw new Error('Invalid tokens');
  }

  if (!accessToken) {
    await loadAccessToken(addBearerPrefix(refreshToken));

    accessToken = getCookie('accessToken');
  }

  let response = await request(url, {
    params,
    method,
    authorization: addBearerPrefix(accessToken),
    signal,
    headers,
    isFile,
  });

  if (response.ok) {
    return response.json();
  }

  await loadAccessToken(addBearerPrefix(refreshToken));

  accessToken = getCookie('accessToken');

  response = await request(url, {
    params,
    method,
    authorization: addBearerPrefix(accessToken),
    signal,
    headers,
    isFile,
  });

  return response.json();
};
