import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { request } from '../../utils/requests';

export const SignUp = () => {
  const { t } = useTranslation();
  const companyNameRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    let err = null;
    if (companyNameRef.current.value.trim().length === 0) {
      err = new Error(t('Company name could not be empty'));
    }
    if (firstnameRef.current.value.trim().length === 0) {
      err = new Error(t('First name could not be empty'));
    }
    if (lastnameRef.current.value.trim().length === 0) {
      err = new Error(t('Last name could not be empty'));
    }
    if (!/^[\w\-._]+@[\w\-_.]+\.\w+$/.test(emailRef.current.value.trim())) {
      err = new Error(t('Fill correct email address'));
    }
    if (passwordRef.current.value.trim().length < 8) {
      err = new Error(t('Password should contains at least 8 symbols'));
    }
    if (repeatPasswordRef.current.value !== passwordRef.current.value) {
      err = new Error(t('Password and Repeat Password should be the same'));
    }

    if (err) {
      setError(err);
      return;
    }

    const response = await request('/auth/register', {
      method: 'POST',
      params: {
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        companyName: companyNameRef.current.value,
      },
    });

    setLoading(false);

    if (!response.ok) {
      setError(new Error(t(response.statusText)));
      return;
    }

    navigate('/sign-in');
  }, []);

  const handleEnterPress = async (event) => {
    if (event.key === 'Enter') {
      await handleSubmit();
    }
  };

  return (
    <Dialog open>
      <DialogTitle>{t('Sign Up')}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1rem',
            paddingTop: '5px',
          }}
          onKeyUp={handleEnterPress}
        >
          <TextField
            size="small"
            label={t('company name')}
            inputRef={companyNameRef}
          />
          <TextField
            size="small"
            label={t('firstname')}
            inputRef={firstnameRef}
          />
          <TextField
            size="small"
            label={t('lastname')}
            inputRef={lastnameRef}
          />
          <TextField size="small" label={t('email')} inputRef={emailRef} />
          <TextField
            size="small"
            label={t('password')}
            inputRef={passwordRef}
            type="password"
          />
          <TextField
            size="small"
            label={t('repeat password')}
            inputRef={repeatPasswordRef}
            type="password"
          />

          {error && <Alert severity="error">{t(error.message)}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{ p: '1.25rem', justifyContent: 'space-between', paddingInline: 3 }}
      >
        <Link component={RouterLink} to="/sign-in" underline="hover">
          {t('sign in')}
        </Link>

        <LoadingButton
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          loading={loading}
        >
          {t('sign up')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
