import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { filter, map } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useLoadListData } from '../../hooks/useLoadListData';

const EditModal = memo(({ open, onClose, onSubmit, permissions }) => {
  const { t } = useTranslation();
  const nameRef = useRef();
  const websiteUrlRef = useRef();
  const descriptionRef = useRef();
  const selectedPermissionsRef = useRef([]);

  useEffect(() => {
    if (open) {
      selectedPermissionsRef.current = [];
    }
  }, [open]);

  const handleCheckboxChange = useCallback((event) => {
    if (event.target.checked) {
      selectedPermissionsRef.current.push(event.target.name);
    } else {
      selectedPermissionsRef.current = filter(
        selectedPermissionsRef.current,
        (item) => item !== event.target.name,
      );
    }
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      tokenName: nameRef.current.value,
      tokenDescription: descriptionRef.current.value,
      permissions: selectedPermissionsRef.current,
      url: websiteUrlRef.current.value,
    });
  }, [onSubmit, permissions]);

  const renderPermission = useCallback(
    (permission) => (
      <Grid item xs={3} key={permission.code}>
        <FormControlLabel
          control={
            <Checkbox name={permission.code} onChange={handleCheckboxChange} />
          }
          label={permission.name}
        />
      </Grid>
    ),
    [],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add api token')}</DialogTitle>

      <DialogContent>
        <Stack
          direction="row"
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '0.75rem',
            }}
          >
            <TextField size="small" label={t('name')} inputRef={nameRef} />
            <TextField
              size="small"
              label={t('website url')}
              inputRef={websiteUrlRef}
            />

            <Grid container>{map(permissions, renderPermission)}</Grid>

            <TextField
              label={t('description')}
              inputRef={descriptionRef}
              multiline
              rows={3}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const ApiTokens = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const url = useRef('/clients');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { list: permissions } = useLoadListData('/permissions');

  const columnsKeys = useMemo(() => ['token_name', 'token', 'description'], []);

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(columnKey),
      })),
    [],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add api token')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleDeleteRow(row)} disabled>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{ isLoading, showAlertBanner: isError, pagination }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        permissions={permissions}
      />
    </>
  );
};
