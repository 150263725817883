import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { filter, find, map, reject, sortBy } from 'lodash';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  Delete,
  Edit,
  ExpandMore,
  TableView,
  ViewKanban,
} from '@mui/icons-material';
import { ControlledBoard } from '@caldwell619/react-kanban';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { useLoadListData } from '../../hooks/useLoadListData';
import '@caldwell619/react-kanban/dist/styles.css';
import { KanbanCard } from '../../views/KanbanCard';
import { KanbanColumnAdder } from '../../views/KanbanColumnAdder';
import { KanbanColumnHeader } from '../../views/KanbanColumnHeader';
import { useUpdateRequest } from '../../hooks/useUpdateRequest';
import { getSelectedCompany } from '../../store/app/slice';
import { useColumns } from '../../hooks/useColumns';
import { OtherField } from '../../views/OtherField';

const ExpandMoreButton = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const EditModal = memo(
  ({ open, onClose, onSubmit, row, statuses, additionalFields }) => {
    const [showAdsMarks, setShowAdsMarks] = useState(false);
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
      values: row ? { ...row, other: JSON.parse(row.other ?? `{}`) } : {},
    });

    const handleClickSubmit = useCallback(() => {
      handleSubmit(({ phone, other, ...data }) =>
        onSubmit?.({
          ...data,
          phone: phone?.replace(/[+\s]/gi, ''),
          other: JSON.stringify(other),
        }),
      )();
      reset();
    }, [onSubmit, handleSubmit, reset]);

    const renderStatusOption = useCallback(
      (status) => (
        <MenuItem key={status.id} value={status}>
          {status.statusName}
        </MenuItem>
      ),
      [],
    );

    return (
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>{t('Add sales lead')}</DialogTitle>

        <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '0.75rem',
              paddingTop: '5px',
            }}
          >
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('firstname')} />
              )}
            />

            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('lastname')} />
              )}
            />

            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('phone')} />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('email')} />
              )}
            />

            <Controller
              name="childFirstname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label={t('childFirstname')}
                />
              )}
            />

            <Controller
              name="childLastname"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('childLastname')} />
              )}
            />

            <Controller
              name="salesLeadsStatus"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  size="small"
                  value={find(statuses, { id: value.id })}
                  onChange={onChange}
                >
                  {map(statuses, renderStatusOption)}
                </Select>
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label={t('notes')}
                  multiline
                  rows={5}
                />
              )}
            />

            {map(additionalFields, (field) => (
              <OtherField control={control} {...field} />
            ))}

            <div>
              <span>Ads info</span>
              <ExpandMoreButton
                expand={showAdsMarks}
                onClick={() => setShowAdsMarks((prevState) => !prevState)}
              >
                <ExpandMore />
              </ExpandMoreButton>
            </div>

            <Collapse in={showAdsMarks} timeout="auto">
              <Stack
                sx={{
                  width: '100%',
                  minWidth: { xs: '300px', sm: '360px', md: '400px' },
                  gap: '0.75rem',
                  paddingTop: '5px',
                }}
              >
                <Controller
                  name="utmSource"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={t('utm_source')}
                    />
                  )}
                />

                <Controller
                  name="utmTerm"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} size="small" label={t('utm_term')} />
                  )}
                />

                <Controller
                  name="utmContent"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={t('utm_content')}
                    />
                  )}
                />

                <Controller
                  name="utmCampaign"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={t('utm_campaign')}
                    />
                  )}
                />

                <Controller
                  name="utmMedium"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={t('utm_medium')}
                    />
                  )}
                />
              </Stack>
            </Collapse>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={handleClickSubmit}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const SalesLeads = () => {
  const company = useSelector(getSelectedCompany);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [view, setView] = useState('table');
  const url = useRef('/sales-leads');
  const statusesUrl = useRef('/sales-leads-statuses');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(() => {
    return {
      page: view === 'table' ? pagination.pageIndex : 0,
      size: view === 'table' ? pagination.pageSize : 200,
      ...((globalFilter?.length ?? 0) > 0 ? { query: globalFilter } : {}),
    };
  }, [pagination, globalFilter, view]);

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);
  const { list: statuses, reload: reloadStatuses } = useLoadListData(
    statusesUrl.current,
  );
  const { save: saveLeadStatus } = useAddTableData(statusesUrl.current);
  const { update: updateLeadStatus } = useUpdateTableData(statusesUrl.current);
  const { remove: removeLeadStatus } = useRemoveTableData(statusesUrl.current);
  const { update: updateSortOrder } = useUpdateRequest(statusesUrl.current);

  const additionalFieldsParams = useRef({
    table: 'sales-leads',
    page: 0,
    size: 200,
  });
  const { rows: additionalFields } = useLoadTableData(
    '/additional-fields',
    additionalFieldsParams.current,
  );

  const columnsKeys = useMemo(
    () => [
      'salesLeadsStatus.statusName',
      'firstname',
      'lastname',
      'email',
      'phone',
      'childFirstname',
      'utmSource',
      'utmTerm',
      'utmContent',
      'utmCampaign',
      'utmMedium',
      ...map(additionalFields, (field) => `other.${field.name}`),
    ],
    [additionalFields],
  );

  const getColumnName = useCallback(
    (columnKey) => {
      if (columnKey.startsWith('other.')) {
        const [, additionalFieldName] = columnKey.split(/\./);

        return find(additionalFields, { name: additionalFieldName })?.title;
      }

      switch (columnKey) {
        case 'salesLeadsStatus.statusName':
          return 'status';
        case 'firstname':
          return 'parent firstname';
        case 'lastname':
          return 'parent lastname';
        case 'utmSource':
          return 'utm_source';
        case 'utmCampaign':
          return 'utm_campaign';
        case 'utmContent':
          return 'utm_content';
        case 'utmMedium':
          return 'utm_medium';
        case 'utmTerm':
          return 'utm_term';
        default:
          return columnKey;
      }
    },
    [additionalFields],
  );

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(getColumnName(columnKey)),
      })),
    [getColumnName],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const handleViewSelect = useCallback((_, [newView]) => {
    setView(newView);
  }, []);

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add sales lead')}
        </Button>
        <ToggleButtonGroup size="small" onChange={handleViewSelect}>
          <ToggleButton value="table">
            <TableView />
          </ToggleButton>
          <ToggleButton value="board">
            <ViewKanban />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  const board = useMemo(
    () => ({
      columns: map(
        sortBy(statuses, ['sortOrder']),
        ({ id, statusName: title, sortOrder }) => ({
          id,
          title,
          sortOrder,
          cards: map(
            filter(rows, (item) => item.salesLeadsStatus.id === id),
            (item) => ({
              id: item.id,
              title: `${item.firstname} ${item.lastname}`,
              description: item.email,
              onEdit: handleUpdateModalOpen({ original: item }),
              onDelete: handleDeleteRow({ original: item }),
              ...item,
            }),
          ),
        }),
      ),
    }),
    [statuses, rows, handleUpdateModalOpen, handleDeleteRow],
  );

  const handleAddNewState = useCallback(
    async (data) => {
      await saveLeadStatus(data);
      await reloadStatuses();
    },
    [saveLeadStatus, reloadStatuses],
  );

  const handleEditStatus = useCallback(
    async (data) => {
      await updateLeadStatus(data);
      await reloadStatuses();
    },
    [updateLeadStatus, reloadStatuses],
  );

  const handleDeleteStatus = useCallback(
    async (id) => {
      await removeLeadStatus(id);
      await reloadStatuses();
    },
    [removeLeadStatus],
  );

  const handleColumnsDragEnd = useCallback(
    async ({ id }, { fromPosition }, { toPosition }) => {
      if (fromPosition !== toPosition && company) {
        const movedStatus = find(statuses, { id });
        const newList = reject(statuses, { id });
        newList.push(movedStatus);

        const sorted = map(newList, (item, index) => ({
          ...item,
          sortOrder: index,
          companyId: company.id,
        }));

        await updateSortOrder(sorted);
        await reloadStatuses();
      }
    },
    [statuses, reloadStatuses, updateSortOrder, company],
  );

  const handleCardDragEnd = useCallback(
    async ({ onDelete, onEdit, ...other }, _, { toColumnId }) => {
      other.salesLeadsStatus = find(statuses, { id: toColumnId });
      other.phone = other.phone?.replace(/\+/gi, '');

      await update(other);
      await reload();
    },
    [statuses, update, reload],
  );

  const { setColumnVisibility, columnVisibility, setColumnOrder, columnOrder } =
    useColumns('sales-leads', columnsKeys);

  return (
    <>
      {view === 'table' && (
        <MaterialReactTable
          columns={columns}
          data={rows}
          rowCount={rowCount}
          getRowId={getRowId}
          muiToolbarAlertBannerProps={errorBanner}
          state={{
            isLoading,
            showAlertBanner: isError,
            pagination,
            columnOrder,
            columnVisibility,
          }}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          onPaginationChange={setPagination}
          manualPagination
          enableRowActions
          renderRowActions={renderRowActions}
          onGlobalFilterChange={setGlobalFilter}
          enableColumnOrdering
          onColumnOrderChange={setColumnOrder}
          onColumnVisibilityChange={setColumnVisibility}
        />
      )}
      {view === 'board' && (
        <Box sx={{ padding: 1 }}>
          {renderTopToolbarCustomActions()}
          <ControlledBoard
            renderCard={KanbanCard}
            allowAddColumn
            renderColumnAdder={() => (
              <KanbanColumnAdder
                onSave={handleAddNewState}
                sortOrder={statuses.length + 1}
              />
            )}
            allowRenameColumn
            renderColumnHeader={(column) => (
              <KanbanColumnHeader
                column={column}
                onEdit={handleEditStatus}
                onDelete={handleDeleteStatus}
              />
            )}
            allowAddCard
            onColumnDragEnd={handleColumnsDragEnd}
            onCardDragEnd={handleCardDragEnd}
          >
            {board}
          </ControlledBoard>
        </Box>
      )}
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        statuses={statuses}
        additionalFields={additionalFields}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        statuses={statuses}
        additionalFields={additionalFields}
      />
    </>
  );
};
