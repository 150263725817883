import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Delete, Edit, ExpandMore, Group, Person } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { useColumns } from '../../hooks/useColumns';
import { OtherField } from '../../views/OtherField';

const EditModal = memo(({ open, onClose, onSubmit, row, additionalFields }) => {
  const { t } = useTranslation();
  const [familiesParams, setFamiliesParams] = useState({
    page: 0,
    size: 200,
    query: '',
  });
  const [newFamilyName, setNewFamilyName] = useState();

  const { rows: families, isLoading: isFamiliesLoading } = useLoadTableData(
    '/families',
    familiesParams,
  );
  const { save } = useAddTableData('/families');

  const defaultFamily = useMemo(
    () => find(families, { id: row?.familyId }),
    [families, row],
  );

  const { handleSubmit, control, reset } = useForm({
    values: row
      ? { ...row, family: defaultFamily, type: row.type.split(';') }
      : { type: [] },
  });

  const handleChangeFamilyInput = useCallback((event, newInputValue) => {
    setFamiliesParams((oldParams) => ({
      ...oldParams,
      query: newInputValue,
    }));
    setNewFamilyName(newInputValue);
  }, []);

  const getFamilyLabel = useCallback((family) => family?.lastname, []);
  const isOptionEqualToValue = useCallback(
    (option, value) => option.id === value.id,
    [],
  );

  const handleClickSubmit = useCallback(() => {
    handleSubmit(async ({ family, phone, ...rest }) => {
      if (!family) {
        const savedFamily = await save({
          lastname: newFamilyName,
          description: null,
        });

        rest.familyId = savedFamily.id;
      } else {
        rest.familyId = family.id;
      }

      const data = {
        ...rest,
        phone: phone?.replace(/[+\s]/gi, ''),
        type: rest.type.join(';'),
      };
      onSubmit?.(data);
    })();
    reset();
  }, [handleSubmit, onSubmit, newFamilyName]);

  const handleAutocompleteChange = useCallback(
    (onChange) => (event, newValue) =>
      onChange({ target: { value: newValue } }),
    [],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add client')}</DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            paddingTop: '5px',
            width: '100%',
            gap: '0.75rem',
          }}
        >
          <FormControl fullWidth>
            <Controller
              name="family"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  name={name}
                  freeSolo
                  size="small"
                  onInputChange={handleChangeFamilyInput}
                  options={families ?? []}
                  value={value}
                  onChange={handleAutocompleteChange(onChange)}
                  getOptionLabel={getFamilyLabel}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Family"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isFamiliesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl>

          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('firstname')} />
            )}
          />

          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('lastname')} />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('email')} />
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('phone')} />
            )}
          />

          <FormControl fullWidth>
            <InputLabel size="small">{t('Client type')}</InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field: { ...field } }) => (
                <Select
                  {...field}
                  size="small"
                  label={t('Client type')}
                  multiple
                >
                  <MenuItem value="student">Student</MenuItem>
                  <MenuItem value="contact_person">Contact Person</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          {map(additionalFields, (field) => (
            <OtherField control={control} {...field} />
          ))}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleClickSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const Clients = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const [view, setView] = useState('clients');

  const url = useRef('/external-users');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);

  const additionalFieldsParams = useRef({
    table: 'external-users',
    page: 0,
    size: 200,
  });
  const { rows: additionalFields } = useLoadTableData(
    '/additional-fields',
    additionalFieldsParams.current,
  );

  const columnsKeys = useMemo(() => {
    if (view === 'clients') {
      return [
        'firstname',
        'lastname',
        'email',
        'phone',
        'notificationsChannel',
        'type',
        ...map(additionalFields, (field) => `other.${field.name}`),
      ];
    }

    if (view === 'families') {
      return ['lastname', 'members'];
    }
  }, [view, additionalFields]);

  const getColumnName = useCallback(
    (columnKey) => {
      if (columnKey.startsWith('other.')) {
        const [, additionalFieldName] = columnKey.split(/\./);

        return find(additionalFields, { name: additionalFieldName })?.title;
      }

      switch (columnKey) {
        default:
          return columnKey;
      }
    },
    [additionalFields],
  );

  const memberRender = useCallback((member) => {
    return (
      <TableRow key={member.id}>
        <TableCell>{member.firstname}</TableCell>
        <TableCell>{member.phone}</TableCell>
        <TableCell>{member.email}</TableCell>
        <TableCell>{member.notificationsChannel}</TableCell>
        <TableCell>{member.type}</TableCell>
      </TableRow>
    );
  }, []);

  const membersRenderer = useCallback(({ members }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Total members: {members?.length}
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('firstname')}</TableCell>
                <TableCell>{t('phone')}</TableCell>
                <TableCell>{t('email')}</TableCell>
                <TableCell>{t('notificationsChannel')}</TableCell>
                <TableCell>{t('type')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{map(members, memberRender)}</TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  }, []);

  const getAccessorFn = useCallback((columnKey) => {
    switch (columnKey) {
      case 'members':
        return membersRenderer;
      default:
        return undefined;
    }
  }, []);

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(getColumnName(columnKey)),
        accessorFn: getAccessorFn(columnKey),
      })),
    [getColumnName, getAccessorFn, view],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const handleChangeView = useCallback((_, [value]) => {
    setView(value);
  }, []);

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add client')}
        </Button>
        <ToggleButtonGroup size="small" onChange={handleChangeView}>
          <ToggleButton value="clients" selected={view === 'clients'}>
            <Person />
          </ToggleButton>
          <ToggleButton
            value="families"
            selected={view === 'families'}
            disabled
          >
            <Group />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    ),
    [handleChangeView, view],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <Tooltip title={t('family should not have members to delete')}>
          <span>
            <IconButton
              size="small"
              onClick={handleDeleteRow(row)}
              disabled={row.original.members && row.original.members.length > 0}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  useEffect(() => {
    if (view === 'clients') {
      url.current = '/external-users';
    }

    if (view === 'families') {
      url.current = '/families';
    }

    setPagination(({ pageSize }) => ({
      pageIndex: 0,
      pageSize,
    }));
  }, [view]);

  const { setColumnVisibility, columnVisibility, setColumnOrder, columnOrder } =
    useColumns('clients', columnsKeys);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{
          isLoading,
          showAlertBanner: isError,
          pagination,
          columnOrder,
          columnVisibility,
        }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        additionalFields={additionalFields}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        additionalFields={additionalFields}
      />
    </>
  );
};
