import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authorizedRequest } from '../../utils/requests';
import { getSelectedCompany } from '../../store/app/slice';

export const useAddTableData = (url) => {
  const company = useSelector(getSelectedCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef();

  const save = useCallback(
    async (params) => {
      setIsLoading(true);

      try {
        controllerRef.current = new AbortController();

        const data = await authorizedRequest(url, {
          headers: {
            'Content-Type': 'application/json',
          },
          signal: controllerRef.current.signal,
          params: {
            ...params,
            companyId: company?.id,
          },
          method: 'POST',
        });

        setIsLoading(false);
        setIsError(false);

        return data;
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    },
    [url, company],
  );

  return { isLoading, isError, save };
};
