import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useCallback, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFileUpload } from '../../hooks/useFileUpload';

const FileInput = ({ onChange, size, label }) => {
  const fileRef = useRef();
  const { upload, isLoading } = useFileUpload();

  const handleSelectFileClick = useCallback(() => {
    fileRef.current?.click();
  });

  const handleFileChange = useCallback(async () => {
    const [file] = fileRef.current?.files ?? [];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const data = await upload(formData);

      onChange({ target: { value: data.code } });
    }
  });

  return (
    <>
      <input type="file" hidden ref={fileRef} onChange={handleFileChange} />
      <LoadingButton
        onClick={handleSelectFileClick}
        loading={isLoading}
        size={size}
      >
        {label}
      </LoadingButton>
    </>
  );
};

export const OtherField = ({ type, name, control, title }) => {
  switch (type) {
    case 'text':
      return (
        <Controller
          name={`other.${name}`}
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" label={title} />
          )}
        />
      );
    case 'file':
      return (
        <Controller
          name={`other.${name}`}
          control={control}
          render={({ field }) => (
            <FileInput {...field} size="small" label={title} />
          )}
        />
      );
    default:
      return null;
  }
};
