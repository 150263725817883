import { convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import { difference, isNil, map, reduce } from 'lodash';
import {
  Divider,
  MenuItem,
  Paper,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from '@mui/icons-material';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { useTranslation } from 'react-i18next';

const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const Fieldset = styled('fieldset')(({ focus }) => ({
  borderStyle: 'solid',
  borderColor: focus ? '#1976d2' : 'rgba(0, 0, 0, 0.23)',
  borderWidth: focus ? 2 : 1,
  borderRadius: 4,
  margin: focus ? -1 : 0,
  marginTop: -1,
  marginBottom: -1,
  padding: 0,
}));

const EditorContainer = styled('div')({
  padding: 10,
  minHeight: 100,
});

const Legend = styled('legend')(({ focus }) => ({
  marginLeft: 12,
  fontSize: 12,
  color: focus ? '#1976d2' : 'rgba(0, 0, 0, 0.6)',
}));

const TextTypeControl = ({ onBlockTypeChange }) => {
  const controlItemsRef = useRef([
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading 1', style: 'header-one' },
    { label: 'Heading 2', style: 'header-two' },
    { label: 'Heading 3', style: 'header-three' },
    { label: 'Heading 4', style: 'header-four' },
    { label: 'Heading 5', style: 'header-five' },
    { label: 'Heading 6', style: 'header-six' },
    { label: 'Numbered List', style: 'ordered-list-item' },
    { label: 'Bulleted List', style: 'unordered-list-item' },
  ]);

  const [value, setValue] = useState(controlItemsRef.current[0].style);

  const renderOption = useCallback(
    ({ label, style }) => (
      <MenuItem value={style} key={style}>
        {label}
      </MenuItem>
    ),
    [],
  );

  const handleSelect = useCallback(
    ({ target: { value: newValue } }) => {
      setValue(newValue);
      onBlockTypeChange(newValue);
    },
    [onBlockTypeChange],
  );

  return (
    <Select
      size="small"
      variant="standard"
      value={value}
      onChange={handleSelect}
    >
      {map(controlItemsRef.current, renderOption)}
    </Select>
  );
};

const TextDecorationControl = ({
  onInlineStyleChange,
  decorations: defaultDecorations,
}) => {
  const controlItemsRef = useRef([
    { label: <FormatBold />, style: 'BOLD' },
    { label: <FormatItalic />, style: 'ITALIC' },
    { label: <FormatUnderlined />, style: 'UNDERLINE' },
    { label: <Code />, style: 'CODE' },
    { label: <FormatStrikethrough />, style: 'STRIKETHROUGH' },
  ]);

  const [decorations, setDecorations] = useState(defaultDecorations);

  useEffect(() => {
    setDecorations(defaultDecorations);
  }, [defaultDecorations]);

  const renderButton = useCallback(
    ({ style, label }) => (
      <ToggleButton value={style} key={style}>
        {label}
      </ToggleButton>
    ),
    [],
  );

  const handleTextDecoration = useCallback((event, newDecorations) => {
    setDecorations((prevState) => {
      onInlineStyleChange([
        ...difference(newDecorations, prevState),
        ...difference(prevState, newDecorations),
      ]);

      return newDecorations;
    });
  }, []);

  return (
    <ToggleButtonGroup
      size="small"
      onChange={handleTextDecoration}
      value={decorations}
    >
      {map(controlItemsRef.current, renderButton)}
    </ToggleButtonGroup>
  );
};

const EmojisControl = () => (
  <>
    <EmojiSuggestions />
    <EmojiSelect closeOnEmojiSelect />
  </>
);

const EditorControls = ({
  onBlockTypeChange,
  onInlineStyleChange,
  decorations,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1.5,
        paddingTop: 0.5,
      }}
    >
      <TextTypeControl onBlockTypeChange={onBlockTypeChange} />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <TextDecorationControl
        onInlineStyleChange={onInlineStyleChange}
        decorations={decorations}
      />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <EmojisControl />
    </Paper>
  );
};

const parseDecorations = (entries) => {
  const { done, value } = entries?.next() ?? {};

  const [decoration] = value ?? [];

  if (done === false) {
    return [decoration, ...parseDecorations(entries)];
  }

  if (decoration) {
    return [decoration];
  }

  return [];
};

const isRaw = (value) => {
  try {
    convertFromRaw(value);
    return true;
  } catch (e) {
    return false;
  }
};

const formatValue = (value) => {
  if (isNil(value)) {
    return createEditorStateWithText('');
  }

  if (isRaw(value)) {
    return EditorState.createWithContent(convertFromRaw(value));
  }

  return createEditorStateWithText(value);
};

export const TextEditor = memo(({ value, onChange }) => {
  const editorRef = useRef();
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(formatValue(value));
  const [decorations, setDecorations] = useState([]);
  const [focus, setFocus] = useState(false);

  const handleKeyCommand = useCallback((command, newEditorState) => {
    const updatedEditorState = RichUtils.handleKeyCommand(
      newEditorState,
      command,
    );

    if (updatedEditorState) {
      setEditorState(updatedEditorState);

      const newDecorations = parseDecorations(
        updatedEditorState.getCurrentInlineStyle().entries(),
      );

      setDecorations(newDecorations);

      return 'handled';
    }

    return 'not-handled';
  }, []);

  const handleBlockTypeChange = useCallback((blockType) => {
    setEditorState((prevState) => {
      return RichUtils.toggleBlockType(prevState, blockType);
    });
  }, []);

  const handleInlineStyleChange = useCallback(
    (newDecorations) =>
      setEditorState((prevState) => {
        return reduce(
          newDecorations,
          (result, decoration) =>
            RichUtils.toggleInlineStyle(result, decoration),
          prevState,
        );
      }),
    [],
  );

  const handleEditorFocus = useCallback(() => {
    editorRef.current?.focus();
  }, []);

  const handleChange = useCallback((newEditorState) => {
    const newDecorations = parseDecorations(
      newEditorState.getCurrentInlineStyle().entries(),
    );

    setDecorations(newDecorations);

    setEditorState(newEditorState);
    onChange?.(convertToRaw(newEditorState.getCurrentContent()));
  }, []);

  const handleFocus = useCallback(() => setFocus(true), []);
  const handleBlur = useCallback(() => setFocus(false), []);

  return (
    <Fieldset focus={focus}>
      <Legend focus={focus}>{t('description')}</Legend>
      <EditorControls
        onBlockTypeChange={handleBlockTypeChange}
        onInlineStyleChange={handleInlineStyleChange}
        decorations={decorations}
      />
      <Divider />
      <EditorContainer onClick={handleEditorFocus}>
        <Editor
          editorState={editorState}
          onChange={handleChange}
          handleKeyCommand={handleKeyCommand}
          ref={editorRef}
          plugins={[emojiPlugin]}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </EditorContainer>
    </Fieldset>
  );
});
