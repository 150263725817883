import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useMemo } from 'react';

export const KanbanCard = ({
  title,
  createdAt,
  phone,
  email,
  onEdit,
  onDelete,
  ...rest
}) => {
  const renderOther = useMemo(
    () =>
      Object.keys(rest).map((key) =>
        typeof rest[key] !== 'object' ? (
          <Typography variant="subtitle2">
            {key}: <Typography variant="caption">{rest[key]}</Typography>
          </Typography>
        ) : null,
      ),
    [rest],
  );

  return (
    <Card sx={{ width: 300 }}>
      <CardHeader
        title={<Typography variant="h6">{title}</Typography>}
        subheader={<Typography variant="caption">{createdAt}</Typography>}
        action={
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
            <IconButton size="small" onClick={onEdit}>
              <Edit />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
              <Delete />
            </IconButton>
          </Box>
        }
        disableTypography
      />
      <CardContent>
        <Typography variant="subtitle2">
          Phone: <Typography variant="caption">{phone}</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Email: <Typography variant="caption">{email}</Typography>
        </Typography>

        <Divider />
        {renderOther}
      </CardContent>
    </Card>
  );
};
