import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authorizedRequest } from '../../utils/requests';
import { getSelectedCompany } from '../../store/app/slice';

export const useUpdateTableData = (url) => {
  const company = useSelector(getSelectedCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef();

  const update = useCallback(
    async (params) => {
      setIsLoading(true);

      try {
        controllerRef.current = new AbortController();

        const { id, ...rest } = params;
        await authorizedRequest(`${url}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          signal: controllerRef.current.signal,
          params: { ...rest, companyId: company?.id },
          method: 'PUT',
        });

        setIsLoading(false);
        setIsError(false);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    },
    [url, company],
  );

  return { isLoading, isError, update };
};
