import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Link,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { setCookie } from '../../utils/cookies';
import { request } from '../../utils/requests';
import { loadUser } from '../../store/app';

export const SignIn = () => {
  const { t } = useTranslation();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async () => {
    setError(false);
    setLoading(true);

    const response = await request('/auth/login', {
      method: 'POST',
      params: {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      },
    });

    setLoading(false);

    if (!response.ok) {
      setError(response.statusText);
      return;
    }

    const data = await response.json();

    setCookie('accessToken', data.accessToken, data.accessTokenExpiration);
    setCookie('refreshToken', data.refreshToken, data.refreshTokenExpiration);

    dispatch(loadUser());
    navigate('/sales-leads');
  }, []);

  const handleEnterPress = async (event) => {
    if (event.key === 'Enter') {
      await handleSubmit();
    }
  };

  return (
    <Dialog open>
      <DialogTitle>{t('sign in')}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1rem',
            paddingTop: '5px',
          }}
          onKeyUp={handleEnterPress}
        >
          <TextField
            label={t('email')}
            inputRef={emailRef}
            name="email"
            type="email"
            size="small"
          />
          <TextField
            label={t('password')}
            inputRef={passwordRef}
            name="password"
            type="password"
            size="small"
          />

          {error && <Alert severity="error">{t(error.message)}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          p: '1.25rem',
          justifyContent: 'space-between',
          paddingInline: 3,
        }}
      >
        <Link component={RouterLink} to="/sign-up" underline="hover">
          {t('sign up')}
        </Link>

        <LoadingButton
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          loading={loading}
        >
          {t('sign in')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
