import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { getSelectedCompany } from '../../store/app/slice';
import { OtherField } from '../../views/OtherField';
import { useColumns } from '../../hooks/useColumns';

const EditModal = memo(
  ({ open, onClose, onSubmit, roles, row, additionalFields }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
      values: row ?? {},
    });

    const handleClickSubmit = useCallback(() => {
      handleSubmit(({ roles: newRoles, phone, other, ...data }) =>
        onSubmit?.({
          ...data,
          phone: phone?.replace(/[+\s]/gi, ''),
          other: JSON.stringify(other),
          roles: map(newRoles, (role) => role.code),
        }),
      )();
      reset();
    }, [onSubmit, handleSubmit, reset]);

    const handleAutocompleteChange = useCallback(
      (onChange) => (event, newValue) =>
        onChange({ target: { value: newValue } }),
      [],
    );

    const getRoleLabel = useCallback((option) => option.name, []);

    const isOptionEqualToValue = useCallback(
      (option, value) => option.id === value.id,
      [],
    );

    return (
      <Dialog open={open}>
        <DialogTitle>{t('Add user')}</DialogTitle>

        <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '0.75rem',
              paddingTop: '5px',
            }}
          >
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('firstname')} />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('lastname')} />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('phone')} />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('email')} />
              )}
            />
            <Controller
              name="passwordHash"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('password')} />
              )}
            />
            <Controller
              name="education"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('education')} />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label={t('description')}
                  multiline
                  rows={3}
                />
              )}
            />
            <Controller
              name="roles"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  name={name}
                  size="small"
                  multiple
                  options={roles ?? []}
                  value={value}
                  onChange={handleAutocompleteChange(onChange)}
                  getOptionLabel={getRoleLabel}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Roles"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              )}
            />
            <Divider />
            {map(additionalFields, (field) => (
              <OtherField control={control} {...field} />
            ))}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={handleClickSubmit}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const Users = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const url = useRef('/users');

  const { t } = useTranslation();
  const selectedCompany = useSelector(getSelectedCompany);

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const rolesParamsRef = useRef({ size: 200, page: 0 });

  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);
  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const users = useMemo(
    () =>
      map(rows, ({ other, ...row }) => ({
        ...row,
        other: other && JSON.parse(other),
      })),
    [rows],
  );
  const { rows: roles } = useLoadTableData('/roles', rolesParamsRef.current);
  const additionalFieldsParams = useRef({
    table: 'users',
    page: 0,
    size: 200,
  });
  const { rows: additionalFields } = useLoadTableData(
    '/additional-fields',
    additionalFieldsParams.current,
  );

  const columnsKeys = useMemo(
    () => [
      'firstname',
      'lastname',
      'email',
      'phone',
      ...map(additionalFields, (field) => `other.${field.name}`),
    ],
    [additionalFields],
  );

  const getHeader = useCallback(
    (columnKey) => {
      if (columnKey.startsWith('other.')) {
        const [, additionalFieldName] = columnKey.split(/\./);

        return find(additionalFields, { name: additionalFieldName }).title;
      }

      return t(columnKey);
    },
    [additionalFields],
  );

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: getHeader(columnKey),
      })),
    [getHeader],
  );

  const handleCloseAddModal = useCallback(async () => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(`${row.original.id}/${selectedCompany?.id}`);
      await reload();
    },
    [selectedCompany],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(
    ({ row }) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
          <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={handleDeleteRow(row)}>
            <Delete />
          </IconButton>
        </Box>
      );
    },
    [handleDeleteRow],
  );

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  const { setColumnVisibility, columnVisibility, setColumnOrder, columnOrder } =
    useColumns('users', columnsKeys);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={users}
        rowCount={rowCount}
        getRowId={(row) => row.id}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        state={{
          isLoading,
          showAlertBanner: isError,
          pagination,
          columnOrder,
          columnVisibility,
        }}
        enableColumnOrdering
        ditingMode="modal"
        enableEditing
        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
            size="small"
          >
            {t('Add user')}
          </Button>
        )}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        roles={roles}
        additionalFields={additionalFields}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        roles={roles}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        additionalFields={additionalFields}
      />
    </>
  );
};
