import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authorizedRequest } from '../../utils/requests';

const initialState = {
  user: null,
  selectedCompany: null,
  error: null,
  loading: false,
};

export const loadUser = createAsyncThunk('loadUser', async () =>
  authorizedRequest('/users/me', {
    method: 'GET',
  }),
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    selectCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.selectedCompany = action.payload.companies[0] ?? null;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(loadUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadUser.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { selectCompany } = appSlice.actions;
export const appReducer = appSlice.reducer;

export const getUser = (state) => state.app.user;
export const getUserLoading = (state) => state.app.loading;
export const getUserLoadingError = (state) => state.app.error;
export const getSelectedCompany = (state) => state.app.selectedCompany;
