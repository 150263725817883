import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Api,
  ContactEmergency,
  Input,
  ManageAccounts,
} from '@mui/icons-material';

export const SettingsLayout = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 64px)',
      }}
    >
      <List>
        <ListItemButton component={Link} to="/settings">
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          <ListItemText>My Settings</ListItemText>
        </ListItemButton>

        <Divider />

        <ListItemButton component={Link} to="/settings/api-tokens">
          <ListItemIcon>
            <Api />
          </ListItemIcon>
          <ListItemText>API Tokens</ListItemText>
        </ListItemButton>

        <ListItemButton component={Link} to="/settings/roles">
          <ListItemIcon>
            <ContactEmergency />
          </ListItemIcon>
          <ListItemText>Roles</ListItemText>
        </ListItemButton>

        <ListItemButton component={Link} to="/settings/additional-fields">
          <ListItemIcon>
            <Input />
          </ListItemIcon>
          <ListItemText>Additional Fields</ListItemText>
        </ListItemButton>
      </List>

      <Divider orientation="vertical" flexItem />

      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};
