import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { useColumns } from '../../hooks/useColumns';

const EditModal = memo(({ open, onClose, onSubmit, row }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    values: row
      ? {
          ...row,
          endDate: row.endDate && dayjs(row?.endDate),
          startDate: row.startDate && dayjs(row?.startDate),
        }
      : {},
  });

  const [usersParams, setUsersParams] = useState({
    page: 0,
    size: 10,
    query: '',
  });
  const [coursesParams, setCoursesParams] = useState({
    page: 0,
    size: 10,
    query: '',
  });
  const [studentsParams, setStudentsParams] = useState({
    page: 0,
    size: 200,
    query: '',
  });

  const { rows: courses, isLoading: isCoursesLoading } = useLoadTableData(
    '/courses',
    coursesParams,
  );
  const { rows: users, isLoading: isUsersLoading } = useLoadTableData(
    '/users',
    usersParams,
  );
  const { rows: students, isLoading: isStudentsLoading } = useLoadTableData(
    '/external-users',
    studentsParams,
  );

  const dateFormatRef = useRef('YYYY-MM-DDTHH:mm:ssZ');

  const handleClickSubmit = useCallback(() => {
    handleSubmit(
      ({
        course,
        endDate,
        externalUsers,
        startDate,
        users: newUsers,
        ...data
      }) =>
        onSubmit?.({
          ...data,
          courseId: course?.id,
          endDate: endDate?.format(dateFormatRef.current),
          externalUsersIds: map(externalUsers, (item) => item.id),
          startDate: startDate?.format(dateFormatRef.current),
          teacherIds: map(newUsers, (item) => item.id),
        }),
    )();
    reset();
  }, [handleSubmit, onSubmit]);

  const handleChangeTeachersInput = useCallback((event, newInputValue) => {
    setUsersParams((oldParams) => ({
      ...oldParams,
      query: newInputValue,
    }));
  }, []);

  const getTeacherLabel = useCallback(
    (option) => `${option.firstname} ${option.lastname}`,
    [],
  );

  const isOptionEqualToValue = useCallback(
    (option, value) => option.id === value.id,
    [],
  );

  const handleChangeCourseInput = useCallback((event, newInputValue) => {
    setCoursesParams((oldParams) => ({
      ...oldParams,
      query: newInputValue,
    }));
  }, []);

  const getCourseLabel = useCallback((option) => option.name, []);

  const handleChangeStudentsInput = useCallback((event, newInputValue) => {
    setStudentsParams((oldParams) => ({
      ...oldParams,
      query: newInputValue,
    }));
  }, []);

  const getClientLabel = useCallback(
    (option) => `${option.firstname} ${option.lastname}`,
    [],
  );

  const handleAutocompleteChange = useCallback(
    (onChange) => (event, newValue) =>
      onChange({ target: { value: newValue } }),
    [],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add group')}</DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <FormControl fullWidth>
            <Controller
              name="users"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  name={name}
                  size="small"
                  multiple
                  onInputChange={handleChangeTeachersInput}
                  options={users ?? []}
                  value={value}
                  onChange={handleAutocompleteChange(onChange)}
                  getOptionLabel={getTeacherLabel}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Teachers"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isUsersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <Controller
              name="course"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  name={name}
                  size="small"
                  onInputChange={handleChangeCourseInput}
                  options={courses ?? []}
                  value={value}
                  onChange={handleAutocompleteChange(onChange)}
                  getOptionLabel={getCourseLabel}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isCoursesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <Controller
              name="externalUsers"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  name={name}
                  size="small"
                  multiple
                  onInputChange={handleChangeStudentsInput}
                  options={students ?? []}
                  value={value}
                  onChange={handleAutocompleteChange(onChange)}
                  getOptionLabel={getClientLabel}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Students"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isStudentsLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl>

          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label={t('price')} />
            )}
          />

          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker {...field} size="small" label={t('startDate')} />
            )}
          />

          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker {...field} size="small" label={t('endDate')} />
            )}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleClickSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const Groups = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const url = useRef('/ongoing-courses');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);

  const columnsKeys = useMemo(
    () => ['course.name', 'students', 'startDate', 'endDate', 'price'],
    [],
  );

  const getColumnName = useCallback((columnKey) => {
    switch (columnKey) {
      case 'course.name':
        return 'name';
      default:
        return columnKey;
    }
  }, []);

  const studentsColumnRenderer = useCallback((row) => {
    return row.externalUsers?.length ?? 0;
  }, []);

  const endDateColumnRenderer = useCallback((row) => {
    if (row.endDate) return new Date(row.endDate).toDateString();
    return null;
  }, []);

  const startDateColumnRenderer = useCallback((row) => {
    if (row.startDate) return new Date(row.startDate).toDateString();
    return null;
  }, []);

  const getAccessorFn = (columnKey) => {
    switch (columnKey) {
      case 'students':
        return studentsColumnRenderer;
      case 'startDate':
        return startDateColumnRenderer;
      case 'endDate':
        return endDateColumnRenderer;
      default:
        return undefined;
    }
  };

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(getColumnName(columnKey)),
        accessorFn: getAccessorFn(columnKey),
      })),
    [getColumnName],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add group')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  const { setColumnVisibility, columnVisibility, setColumnOrder, columnOrder } =
    useColumns('groups', columnsKeys);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{
          isLoading,
          showAlertBanner: isError,
          pagination,
          columnOrder,
          columnVisibility,
        }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
      />
    </>
  );
};
