import { useCallback, useRef, useState } from 'react';
import { authorizedRequest } from '../../utils/requests';

export const useUpdateRequest = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef();

  const update = useCallback(
    async (params) => {
      setIsLoading(true);

      try {
        controllerRef.current = new AbortController();

        await authorizedRequest(url, {
          headers: {
            'Content-Type': 'application/json',
          },
          signal: controllerRef.current.signal,
          params,
          method: 'PUT',
        });

        setIsLoading(false);
        setIsError(false);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    },
    [url],
  );

  return { isLoading, isError, update };
};
