import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authorizedRequest } from '../../utils/requests';
import { getSelectedCompany } from '../../store/app/slice';

export const useLoadListData = (url, params) => {
  const company = useSelector(getSelectedCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [list, setList] = useState([]);
  const [reloader, setReloader] = useState(0);

  const controllerRef = useRef();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);

      try {
        controllerRef.current = new AbortController();

        const response = await authorizedRequest(
          `${url}?${new URLSearchParams({
            ...params,
            companyId: company?.id,
          })}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            signal: controllerRef.current.signal,
          },
        );

        setList(response);
        setIsLoading(false);
        setIsError(false);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    };

    load();

    return () => {
      setIsLoading(false);
      setIsError(false);
      controllerRef.current.abort();
    };
  }, [url, params, company, reloader]);

  const reload = useCallback(() => {
    setReloader((old) => old + 1);
  }, []);

  return { isLoading, list, isError, reload };
};
