import { useCallback, useEffect, useState } from 'react';
import { reject, some } from 'lodash';

const useVisibleColumns = (tableName) => {
  const getColumnsVisibility = useCallback(() => {
    return JSON.parse(
      localStorage.getItem(`${tableName}-columns-visibility`) ?? `{}`,
    );
  }, []);

  const [columnVisibility, setColumnVisibility] = useState(
    getColumnsVisibility(),
  );

  const handleColumnVisibilityChange = useCallback((getter) => {
    setColumnVisibility((prevState) => {
      const newState = { ...prevState, ...getter() };

      localStorage.setItem(
        `${tableName}-columns-visibility`,
        JSON.stringify(newState),
      );

      return newState;
    });
  }, []);

  return {
    columnVisibility,
    setColumnVisibility: handleColumnVisibilityChange,
  };
};

const useSortColumns = (tableName, columnsKeys) => {
  const getColumnsOrder = useCallback((columnsKeysList) => {
    const list = JSON.parse(
      localStorage.getItem(`${tableName}-columns-order`) ?? `[]`,
    );
    const newColumns = reject(columnsKeysList, (col) =>
      some(list, (item) => item === col),
    );

    list.push(...newColumns);

    if (!some(list, (item) => item === 'mrt-row-actions')) {
      return ['mrt-row-actions', ...list];
    }

    return list;
  }, []);

  const [columnOrder, setColumnOrder] = useState(getColumnsOrder(columnsKeys));

  const handleColumnOrderChange = useCallback((value) => {
    setColumnOrder(value);
    localStorage.setItem(`${tableName}-columns-order`, JSON.stringify(value));
  }, []);

  useEffect(() => {
    setColumnOrder(getColumnsOrder(columnsKeys));
  }, [columnsKeys]);

  return { columnOrder, setColumnOrder: handleColumnOrderChange };
};

export const useColumns = (tableName, columnKeys) => {
  return {
    ...useVisibleColumns(tableName),
    ...useSortColumns(tableName, columnKeys),
  };
};
