export const setCookie = (name, value, expires) => {
  const date = new Date(expires).toISOString();
  document.cookie = `${name}=${value}; expires=${date};`;
};

export const getCookie = (name) => {
  const pattern = RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  );
  const matches = pattern.exec(document.cookie);
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = (name) => {
  setCookie(name, '', 0);
};
