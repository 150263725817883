import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { filter, find, map, some } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';
import { useLoadListData } from '../../hooks/useLoadListData';

const EditModal = memo(({ open, onClose, onSubmit, row, permissions }) => {
  const { t } = useTranslation();
  const nameRef = useRef();
  const codeRef = useRef();
  const descriptionRef = useRef();
  const selectedPermissionsRef = useRef([]);

  useEffect(() => {
    if (open && !row) {
      selectedPermissionsRef.current = [];
    }

    if (open && row) {
      selectedPermissionsRef.current = map(
        row?.permissions,
        (permission) => permission.code,
      );
    }
  }, [open]);

  const handleCheckboxChange = useCallback((event) => {
    if (event.target.checked) {
      selectedPermissionsRef.current.push(event.target.name);
    } else {
      selectedPermissionsRef.current = filter(
        selectedPermissionsRef.current,
        (item) => item !== event.target.name,
      );
    }
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      name: nameRef.current.value,
      code: codeRef.current.value,
      description: descriptionRef.current.value,
      permissions: map(selectedPermissionsRef.current, (code) =>
        find(permissions, { code }),
      ),
      ...(row ? { id: row.id } : {}),
    });
  }, [onSubmit, row, permissions]);

  const renderPermission = useCallback(
    (permission) => (
      <Grid item xs={3} key={permission.code}>
        <FormControlLabel
          control={
            <Checkbox
              name={permission.code}
              onChange={handleCheckboxChange}
              defaultChecked={some(row?.permissions, { code: permission.code })}
            />
          }
          label={permission.name}
        />
      </Grid>
    ),
    [row],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add role')}</DialogTitle>

      <DialogContent>
        <Stack
          direction="row"
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '0.75rem',
            }}
          >
            <TextField
              size="small"
              label={t('name')}
              inputRef={nameRef}
              defaultValue={row?.name}
            />
            <TextField
              size="small"
              label={t('code')}
              inputRef={codeRef}
              defaultValue={row?.code}
            />

            <Grid container>{map(permissions, renderPermission)}</Grid>

            <TextField
              label={t('description')}
              inputRef={descriptionRef}
              multiline
              rows={3}
              defaultValue={row?.description}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const Roles = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const url = useRef('/roles');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);
  const { list: permissions } = useLoadListData('/permissions');

  const columnsKeys = useMemo(() => ['name', 'code', 'description'], []);

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(columnKey),
      })),
    [],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add role')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    if (
      row.original.code === 'COMPANY_OWNER' ||
      row.original.code === 'COMPANY_ADMIN' ||
      row.original.code === 'COMPANY_SALES_MANAGER' ||
      row.original.code === 'COMPANY_ADS_MANAGER' ||
      row.original.code === 'COMPANY_TEACHER'
    ) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
          <IconButton
            size="small"
            onClick={handleUpdateModalOpen(row)}
            disabled
          >
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={handleDeleteRow(row)} disabled>
            <Delete />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(async (data) => {
    await update(data);
    setUpdateRow(null);
    await reload();
  }, []);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{ isLoading, showAlertBanner: isError, pagination }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        permissions={permissions}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
        permissions={permissions}
      />
    </>
  );
};
