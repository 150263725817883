import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowCircleUp, Delete, Edit } from '@mui/icons-material';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';

const FieldsList = memo(() => {
  const { t } = useTranslation();

  const [, setFields] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [required, setRequired] = useState('');

  const handleFieldNameChange = useCallback(
    (event) => setName(event.target.value),
    [],
  );
  const handleFieldTypeChange = useCallback(
    (event) => setType(event.target.value),
    [],
  );
  const handleFieldSourceChange = useCallback(
    (event) => setSource(event.target.value),
    [],
  );
  const handleDestinationChange = useCallback(
    (event) => setDestination(event.target.value),
    [],
  );
  const handleRequiredChange = useCallback(
    (event) => setRequired(event.target.checked),
    [],
  );

  const fieldTypesListRef = useRef(['string', 'phone', 'email', 'list']);
  const sourcesListRef = useRef(['courses']);
  const destinationsListRef = useRef([
    'firstname',
    'lastname',
    'phone',
    'email',
    'child_firstname',
    'child_lastname',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]);

  const renderOption = useCallback(
    (value) => <MenuItem value={value}>{t(value)}</MenuItem>,
    [],
  );

  const handleAddField = useCallback(() => {
    setFields((oldFields) => {
      return [
        ...oldFields,
        {
          name,
          type,
          source,
          destination,
          required,
        },
      ];
    });
  }, [name, type, source, destination, required]);

  return (
    <Stack
      sx={{
        paddingTop: '1.5rem',
      }}
    >
      <Typography variant="h6">Fields list</Typography>
      <List>
        <Divider />
        <ListItem sx={{ paddingLeft: 0, paddingRight: 0, gap: '0.75rem' }}>
          <TextField
            size="small"
            label={t('field name')}
            fullWidth
            value={name}
            onChange={handleFieldNameChange}
          />
          <FormControl fullWidth>
            <InputLabel size="small">{t('field type')}</InputLabel>
            <Select
              label={t('field type')}
              size="small"
              defaultValue={fieldTypesListRef.current[0]}
              value={type}
              onChange={handleFieldTypeChange}
            >
              {map(fieldTypesListRef.current, renderOption)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel size="small">{t('source')}</InputLabel>
            <Select
              label={t('source')}
              size="small"
              defaultValue={sourcesListRef.current[0]}
              value={source}
              onChange={handleFieldSourceChange}
            >
              {map(sourcesListRef.current, renderOption)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel size="small">{t('destination')}</InputLabel>
            <Select
              label={t('destination')}
              size="small"
              defaultValue={destinationsListRef.current[0]}
              value={destination}
              onChange={handleDestinationChange}
            >
              {map(destinationsListRef.current, renderOption)}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox checked={required} onChange={handleRequiredChange} />
            }
            label={t('required')}
          />
          <IconButton color="primary" onClick={handleAddField}>
            <ArrowCircleUp />
          </IconButton>
        </ListItem>
      </List>
    </Stack>
  );
});

const EditModal = memo(({ open, onClose, onSubmit, row }) => {
  const { t } = useTranslation();

  const nameRef = useRef();
  const destinationRef = useRef();
  const descriptionRef = useRef();

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      name: nameRef.current.value,
      destination: destinationRef.current.value,
      description: descriptionRef.current.value,
      ...(row ? { id: row.id } : {}),
    });
  }, [onSubmit, row]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>{t('Add form')}</DialogTitle>

      <DialogContent>
        <Stack
          direction="row"
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '1.35rem',
            }}
          >
            <TextField
              size="small"
              label={t('name')}
              inputRef={nameRef}
              defaultValue={row?.name}
            />
            <FormControl fullWidth>
              <InputLabel size="small">Destination</InputLabel>
              <Select
                inputRef={destinationRef}
                size="small"
                defaultValue={row?.destination ?? 'sales_leads'}
                label={t('Destination')}
              >
                <MenuItem value="sales_leads">Sales Leads</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              gap: '0.75rem',
            }}
          >
            <TextField
              label={t('Description')}
              inputRef={descriptionRef}
              multiline
              rows={3}
              defaultValue={row?.description}
            />
          </Stack>
        </Stack>
        <FieldsList />
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const Forms = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const url = useRef('/forms');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);

  const columnsKeys = useMemo(() => ['name', 'destination', 'description'], []);

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(columnKey),
      })),
    [],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add form')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(async (data) => {
    await update(data);
    setUpdateRow(null);
    await reload();
  }, []);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{ isLoading, showAlertBanner: isError, pagination }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
      />
    </>
  );
};
