import React, { useCallback, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { AccountCircle } from '@mui/icons-material';
import {
  getSelectedCompany,
  getUser,
  getUserLoadingError,
  selectCompany,
} from '../../store/app/slice';
import { isRoutePublic } from '../../utils/routes';

export const Layout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLoadingError = useSelector(getUserLoadingError);
  const user = useSelector(getUser);
  const selectedCompany = useSelector(getSelectedCompany);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelectCompany = useCallback((company) => {
    dispatch(selectCompany(company));
  }, []);

  const handleOpenUserMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseUserMenu = useCallback(
    (path) => () => {
      setAnchorEl(null);

      if (path) {
        navigate(path);
      }
    },
    [],
  );

  const hideMenu = useMemo(
    () => userLoadingError || isRoutePublic(location.pathname),
    [userLoadingError, location.pathname],
  );

  const menuList = useMemo(() => {
    if (hideMenu) {
      return null;
    }

    return (
      <>
        <Button
          variant="button"
          component={Link}
          to="/sales-leads"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('sales leads')}
        </Button>

        <Button
          variant="button"
          component={Link}
          to="/clients"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('clients')}
        </Button>

        <Button
          variant="button"
          component={Link}
          to="/groups"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('groups')}
        </Button>

        <Button
          variant="button"
          component={Link}
          to="/courses"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('courses')}
        </Button>

        <Button
          variant="button"
          component={Link}
          to="/users"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('users')}
        </Button>

        <Button
          variant="button"
          component={Link}
          to="/forms"
          sx={{ textDecoration: 'none', color: '#fff', display: 'block' }}
        >
          {t('forms')}
        </Button>

        {/* <Button */}
        {/*  variant="button" */}
        {/*  component={Link} */}
        {/*  to="/schedule" */}
        {/*  sx={{ textDecoration: 'none', color: '#fff', display: 'block' }} */}
        {/* > */}
        {/*  {t('schedule')} */}
        {/* </Button> */}

        {/* <Button */}
        {/*  variant="button" */}
        {/*  component={Link} */}
        {/*  to="/events" */}
        {/*  sx={{ textDecoration: 'none', color: '#fff', display: 'block' }} */}
        {/* > */}
        {/*  {t('events')} */}
        {/* </Button> */}
      </>
    );
  }, [hideMenu]);

  const renderCompanyOption = useCallback((company) => (
    <MenuItem value={company} key={company.id}>
      {company.name}
    </MenuItem>
  ));

  const companiesSelect = useMemo(() => {
    if (hideMenu) {
      return null;
    }

    return (
      <Select
        sx={{
          textDecoration: 'none',
          color: '#fff',
          display: 'block',
          '&>fieldset': {
            border: 'none',
          },
        }}
        value={selectedCompany ?? ''}
        onChange={handleSelectCompany}
      >
        {map(user?.companies, renderCompanyOption)}
      </Select>
    );
  }, [selectedCompany, user?.companies, hideMenu]);

  const userMenu = useMemo(() => {
    if (hideMenu) {
      return null;
    }

    return (
      <>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenUserMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu()}
        >
          <MenuItem onClick={handleCloseUserMenu('/settings')}>
            {t('Settings')}
          </MenuItem>

          <MenuItem onClick={handleCloseUserMenu('/sign-out')}>
            {t('Sign out')}
          </MenuItem>
        </Menu>
      </>
    );
  }, [hideMenu, anchorEl]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>{menuList}</Box>
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
              {companiesSelect}
              {userMenu}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
};
