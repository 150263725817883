import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../utils/cookies';

export const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    deleteCookie('accessToken');
    deleteCookie('refreshToken');

    navigate('/sign-in');
  }, []);

  return null;
};
