import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getUser, getUserLoadingError, loadUser } from '../../store/app/slice';
import { isRoutePublic } from '../../utils/routes';

export const useAppInit = () => {
  const dispatch = useDispatch();
  const userLoadingError = useSelector(getUserLoadingError);
  const user = useSelector(getUser);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (userLoadingError && !isRoutePublic(location.pathname)) {
      navigate('/sign-in');
    }

    if (!userLoadingError && user && isRoutePublic(location.pathname)) {
      navigate('/sales-leads');
    }
  }, [userLoadingError, user]);
};
