import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useLoadTableData } from '../../hooks/useLoadTableData';
import { useAddTableData } from '../../hooks/useAddTableData';
import { useRemoveTableData } from '../../hooks/useRemoveTableData';
import { useLoadListData } from '../../hooks/useLoadListData';
import { useUpdateTableData } from '../../hooks/useUpdateTableData';

const EditModal = memo(({ open, onClose, onSubmit, row }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    values: row ?? {},
  });

  const handleSubmitForm = useCallback(() => {
    handleSubmit(onSubmit)();
    reset();
  }, [reset, handleSubmit]);

  const tableOptions = useRef([
    { value: 'sales-leads', label: 'Sales Leads' },
    { value: 'courses', label: 'Courses' },
    { value: 'external-users', label: 'Clients' },
    { value: 'users', label: 'Users' },
  ]);

  const typeOptions = useRef([
    { value: 'text', label: 'Text' },
    { value: 'select', label: 'Select' },
    { value: 'multiselect', label: 'Multi Select' },
    { value: 'file', label: 'File' },
  ]);

  const renderOption = useCallback(
    ({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ),
    [],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('Add additional field')}</DialogTitle>

      <DialogContent>
        <Stack
          direction="row"
          sx={{
            paddingTop: '5px',
            gap: '0.75rem',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '0.75rem',
            }}
          >
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('title')} />
              )}
            />

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label={t('name')} />
              )}
            />

            <FormControl fullWidth>
              <InputLabel size="small">{t('table name')}</InputLabel>
              <Controller
                name="table"
                control={control}
                render={({ field }) => (
                  <Select {...field} size="small" label={t('table name')}>
                    {map(tableOptions.current, renderOption)}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel size="small">{t('type')}</InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select {...field} size="small" label={t('type')}>
                    {map(typeOptions.current, renderOption)}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmitForm} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const AdditionalFields = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const url = useRef('/additional-fields');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      page: pagination.pageIndex,
      size: pagination.pageSize,
    }),
    [pagination],
  );

  const { rows, rowCount, isLoading, isError, reload } = useLoadTableData(
    url.current,
    params,
  );
  const { save } = useAddTableData(url.current);
  const { remove } = useRemoveTableData(url.current);
  const { update } = useUpdateTableData(url.current);
  const { list: permissions } = useLoadListData('/permissions');

  const columnsKeys = useMemo(() => ['title', 'name', 'table', 'type'], []);

  const columns = useMemo(
    () =>
      map(columnsKeys, (columnKey) => ({
        accessorKey: columnKey,
        header: t(columnKey),
      })),
    [],
  );

  const handleCloseAddModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleSubmitAddModal = useCallback(
    async (data) => {
      await save(data);
      await reload();
      setCreateModalOpen(false);
    },
    [save],
  );

  const renderTopToolbarCustomActions = useCallback(
    () => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          color="primary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
          size="small"
        >
          {t('Add additional field')}
        </Button>
      </Box>
    ),
    [],
  );

  const getRowId = useCallback((row) => row.id, []);

  const errorBanner = useMemo(() => {
    if (isError) {
      return {
        color: 'error',
        children: 'Error loading data',
      };
    }

    return undefined;
  }, [isError]);

  const handleUpdateModalOpen = useCallback(
    (row) => () => {
      setUpdateRow(row.original);
    },
    [],
  );

  const handleDeleteRow = useCallback(
    (row) => async () => {
      await remove(row.original.id);
      await reload();
    },
    [],
  );

  const renderRowActions = useCallback(({ row }) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        <IconButton size="small" onClick={handleUpdateModalOpen(row)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDeleteRow(row)}>
          <Delete />
        </IconButton>
      </Box>
    );
  }, []);

  const handleCloseUpdateModal = useCallback(() => {
    setUpdateRow(null);
  }, []);

  const handleSubmitUpdateModal = useCallback(
    async (data) => {
      await update(data);
      setUpdateRow(null);
      await reload();
    },
    [update],
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        getRowId={getRowId}
        muiToolbarAlertBannerProps={errorBanner}
        state={{ isLoading, showAlertBanner: isError, pagination }}
        enableColumnOrdering
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        onPaginationChange={setPagination}
        manualPagination
        enableRowActions
        renderRowActions={renderRowActions}
      />
      <EditModal
        open={createModalOpen}
        onClose={handleCloseAddModal}
        onSubmit={handleSubmitAddModal}
        permissions={permissions}
      />
      <EditModal
        open={Boolean(updateRow)}
        row={updateRow}
        onClose={handleCloseUpdateModal}
        onSubmit={handleSubmitUpdateModal}
      />
    </>
  );
};
